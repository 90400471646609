@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;

  --primary-color: #edf2f8;
  /* --secondary-color: #313bac; */
  --secondary-color: #1a67d3;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #00e5f6;
  /* --gray-color: #6b7688; */
  /* --brown-color: #46364a; */
  --yellow-color: #fcd358;
  --white-color: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

.YouTube {
  overflow-x: hidden;
  position: relative;
  display: flex;
  width: 100vh;
  justify-content: center;
}
.LevelDesigns {
  text-align: center;
  padding-bottom: 2rem;
  background: var(--primary-light-grey);
}
